import init from '@frontend/init';
import Cart from '@frontend/components/Cart';
import Search from '@frontend/components/Search';
import Messages from '@frontend/components/Messages';
import Filters from '@frontend/components/Filters';
import Price from '@frontend/components/Price';
import Account from '@frontend/components/Account';
import RegionData from '@frontend/components/RegionData';

import { initPrivateData } from '@frontend/utilities/private-data';
import '@frontend/utilities/form-key';
import '@frontend/utilities/varienform';
import '@frontend/utilities/translate';
import '@frontend/utilities/validation/standard-validations';
import '@frontend/utilities/region-updater';
import '@frontend/utilities/data-post';
import '@frontend/utilities/delayed-scripts';

init({
    Cart,
    Search,
    Messages,
    Filters,
    Price,
    Account,
    RegionData
});
initPrivateData();

import LazyLoad from "vanilla-lazyload";

// @ts-ignore
const lazyload_instance = new LazyLoad({
    elements_selector: '[data-src],[data-bg]'
});
window['lazyLoadInstance'] = lazyload_instance;
